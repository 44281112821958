/* Style for the draggable item
.draggable-item {
  padding: 10px;
  margin-bottom: 10px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  transition: background-color 0.2s ease;
}

.draggable-item:hover {
  background-color: #f8f8f8;
}

.droppable-container {
  padding: 10px;
  margin-bottom: 10px;
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.draggable-item {
  user-select: none;
  margin: 8px;
  padding: 16px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 2px;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
} */


.draggable-item {
  list-style: none;
}

.droppable-container {
  padding-left: 0px;
}

.FAQHeading {
  font-size: 24px !important;
  padding-top: 30px;
}

.flexCard {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.FAQTextarea {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  background: #ececec;
  border-bottom: 1px solid #d0c6b9 !important;
  border-radius: 3px;
  height: 100px;
  outline: none;
  padding: 10px;
  width: 500px;
  border: none;
  font-size: 1rem;
}

.iconExpanded {
  fill: white !important;
}

.infoDiv {
  /* display: flex; */
  margin-top: 4%;
  background: #f8f8f8;
  padding: 1%;
  border-bottom: 2px solid #e3e3e3;
  padding-left: 3%
}

.FAQSpinner {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  margin-top: 10%;
  margin-bottom: 10%
}

.FAQSpinner>span>svg {
  color: black;
}

.inputFAQ {
  height: 40px;
  width: 100%;
  font-size: 14px;
  border: none;
  background: #ececec;
  border-radius: 11px;
  padding: 14px
}

.inputFAQ:focus-visible {
  outline: none;
}

.textAreaFAQ {
  height: 130px !important;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.dragList>div {
  width: 100%
}

.dragList {
  padding: 0px !important;
}

.WritingTipsCard {
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  overflow: hidden;
  margin-top: 2%;
  background: #101828;
  color: white;
  padding: 20px;

}