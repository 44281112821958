.spanElipse {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100px;
}

.imgFeature {
  height: 200px;
  width: auto;
}

.textAreaFeature {
  width: 100%;
  padding: 10px;
  height: 120px;
  margin-bottom: 20px;
}

.textAreaFeature:focus-visible {
  outline: none;
}

.instructions {
  height: 120px;
  padding: 15px;
  border: 1.5px solid #bfbfbf;
  border-radius: 2px;
  width: 100%;
}

.instructions:focus-visible {
  outline: none;
}

.uploadImageArtist {
  display: flex;
  margin-top: 20px;
  justify-content: center;
  margin-bottom: 20px;
  position: relative;
  width: fit-content;
  align-items: center;
  margin: 0 auto;
  padding-bottom: 20px;
}

.editImage {
  height: 200px;
  width: 100%;
  margin-top: 20px;
  object-fit: cover;
  border-radius: 24px;
}

.addArtistButton {
  background: black;
  border: none;
  border-radius: 8px;
  width: 100%;
  color: white;
  font-size: 16px;
  padding: 10px;
  cursor: pointer;
  display: flex;
  justify-content: center;
}

.signUpInput {
  width: 100%;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid black;
  font-size: 16px;
  margin-bottom: 10px;
}

.signUpInput:focus-visible {
  outline: none;
}

.addArtist {
  background: black;
  border: none;
  color: white;
  border-radius: 8px;
  height: 40px;
  cursor: pointer;
  padding: 10px;
}

.croppedDialog > div > div {
  min-height: 55% !important;
}

.zoom-range {
  -webkit-appearance: checkbox !important;
}

.crop-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 80px;
}

.controls {
  position: absolute;
  bottom: 20px;
  left: 50%;
  width: 50%;
  transform: translateX(-50%);
  height: 40px;
  display: flex;
  align-items: center;
}

.slider {
  padding: 22px 0px;
}

.zoom-range {
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 2px;
  border-top: 5px solid #fff;
  border-bottom: 5px solid #fff;
  background: #3f51b5;
  width: 100%;
}

.zoom-range::-moz-range-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid #3f51b5;
  background: #3f51b5;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.zoom-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid #3f51b5;
  background: #3f51b5;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.controls:hover input[type="range"]::-webkit-slider-thumb {
  box-shadow: 0px 0px 0px 8px rgba(63, 81, 181, 0.16);
  border-radius: 50%;
}

.controls:hover input[type="range"]::-moz-range-thumb {
  box-shadow: 0px 0px 0px 8px rgba(63, 81, 181, 0.16);
}

.imageLink {
  height: 126px;
  width: 100%;
  object-fit: cover;
  cursor: pointer;
}

.labelGreen {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #50934b;
}

.progressBar {
  margin-top: 10px;
  width: 86%;
  justify-content: end;
  display: inline-table;
}

.labelForUpload {
  padding: 16px;
}

.uploadedImageText {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #344054;
  margin-left: 14px;
}

.fileSize {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #667085;
}

.labelGrey {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #868e96;
}

.uploadImageTab {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
}

.upholder {
  cursor: pointer;
}

.descriptionAddArt {
  resize: none;
  background: #f9fafb;
  border: 1px solid #d0d5dd;
  border-radius: 24px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  color: #0f1106;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  height: 148px !important;
  line-height: 24px;
  padding: 14px;
  width: 100%;
}

.addArtInput {
  background-color: #f9fafb;
  border: 1px solid #d0d5dd;
  border-radius: 24px;
  color: #0f1106;
  font-size: 24px;
  height: 52px;
  outline: 0;
  width: 100%;
  font-weight: 900;
}

.uploadPaidImage {
  height: 100px;
  justify-content: center;
  display: flex;
  align-items: center;
  border: 1px solid #ececec;
  border-radius: 12px;
  margin-top: 5%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.uploadedPaidImage {
  display: flex;
  border-radius: 12px;
  margin-top: 5%;
  cursor: pointer;
  width: 100%;
  object-fit: cover;
  width: 336px;
  height: 428px;
}

.disc {
  margin: auto auto 24px;
  display: flex;
  border-radius: 50%;
  -webkit-box-align: center;
  align-items: center;
  width: 64px;
  height: 64px;
  -webkit-box-pack: center;
  justify-content: center;
}

.gridStyle {
  border-radius: 12px;
  height: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
}

.css-h5u7p0-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border-color: grey !important;
  border-width: 1px !important;
}

.selectedNameAutoComplete {
  justify-content: space-between;
  border-radius: 5px;
  border: 1px solid #d0d5dd;
  padding-right: 9px;
  padding-left: 9px;
}

.displayAlign {
  display: flex;
  align-items: center;
}

.testimonial {
  padding: 24px;
  border: 1px solid black;
  border-radius: 5px;
  margin-top: 30px;
}

.testimonialUser {
  display: flex;
}

.autoCompleteInput > div {
  padding-right: 9px !important;
}

.tagsGenre {
  background: black;
  color: white;
  padding: 10px;
  margin-right: 2%;
  border-radius: 10px;
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.spinnerLoader {
  width: 23px !important;
  height: 23px !important;
}

/* .tagsGenre {
  padding: 5px 15px;
  background: black;
  color: white;
  border-radius: 5%;
  display: flex;
  align-items: center;
  margin-right: 4%;
} */

.error {
  display: flex;
  justify-content: center;
  margin-top: 10%;
  text-align: center;
  flex-direction: column;
  align-items: center;
}

.dangerousHTML {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 2%;
  text-align: left;
}

.uploaded {
  border: 1px solid #50934b;
  border-radius: 11px;
  padding: 20px;
  min-height: 126px;
}

.boxTags {
  display: flex;
  justify-content: space-between;
}

.boxTags > div {
  width: 100%;
}

.tagHeading {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #0f1106;
  margin-bottom: 5px;
}

.tagText {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #0f1106;
  margin-right: 4px;
}

.tagsComp {
  border: 1px solid #d0d5dd;
  display: flex;
  align-items: center;
  /* max-width: 95%; */
  overflow: scroll;
  padding-left: 15px;
  border-radius: 24px;
  margin-bottom: 10px;
}

.tagsComp::-webkit-scrollbar {
  display: none;
}

.inputTags {
  border: none;
  height: 44px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-left: none;
}

.tagsInside {
  height: 22px;
  background: #f2f4f7;
  border-radius: 16px;
  display: flex;
  align-items: center;
  padding: 2px 6px 2px 8px;
  margin-right: 10px;
}

.inputTags:focus-visible {
  outline: none;
}

.approveStory {
  background: #50934b;
  padding: 7px;
  border-radius: 10px;
  color: white;
}

.proviteStory {
  background: black;
  padding: 7px;
  border-radius: 10px;
  color: white;
}

.subMissionDialog > div > div {
  min-width: 800px;
}

.hide-scrollbar {
  scrollbar-width: none;
  -ms-overflow-style: none;
  overflow-y: scroll;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.react-datepicker-popper {
  position: fixed !important;
}

.layoutDrawer > div::-webkit-scrollbar {
  display: none !important;
}

.datePickerContainer {
  position: relative;
  z-index: 9999;
}

.react-datepicker__input-container > input {
  height: 40px;
  padding: 15px;
  border: 1.5px solid #bfbfbf;
  border-radius: 2px;
  width: 100%;
}

.noDataText {
  font-style: normal;
  font-weight: 700;
  font-size: 23.7624px;
  line-height: 36px;
  text-align: center;
  color: #101828;
}

.noDataBox {
  display: flex;
  justify-content: center;
  margin-top: 7%;
}

.selection {
  width: 100%;
  font-size: 14px;
  border: none;
  background: #ececec;
  border-radius: 11px;
  padding: 14px;
  outline: none;
}

.menuTags {
  padding: 3px 8px;
  border: 1px solid #979393;
  border-radius: 5px;
  margin: 5px 10px 5px 0px;
}

.uploadImageSpan {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #50934b;
  font-weight: 900;
  font-family: "Open Sans";
  margin-right: 5px;
  font-family: Helvetica Neue;
}

.uploadImageText {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 900;
  font-size: 14px;
  line-height: 20px;
  font-weight: 900;
  color: #868e96;
  font-family: Helvetica Neue;
}

.uploadCustomButton {
  text-align: center;
  padding: 12px 0px;
  border: 1px solid #d0d5dd;
  border-radius: 24px;
  cursor: pointer;
  height: 126px;
}

.imageUploaded {
  height: 220px;
  width: 100%;
  object-fit: cover;
}

.inputSearch {
  border-radius: 12px;
  border: none;
  background-color: #f8f8f9;
  height: 56px;
  width: 100%;
  padding-left: 10px;
  font-size: 16px;
}

.inputSearch:focus-visible {
  outline: none;
}

.cardLogin {
  min-width: 594px !important;
}

.dialogContainer > div > div {
  min-width: 900px !important;
}

.layoutDrawer > div > div {
  padding-top: 24px;
}

.MenuListing > div.MuiMenu-paper > ul {
  padding-top: 0px;
  padding-bottom: 0px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.MenuListing > div.MuiMenu-paper {
  border-radius: 11px;
}

.drawerSide > div > .MuiPaper-root ::-webkit-scrollbar {
  display: none;
}

.customButton {
  background: #000000 !important;
  border: 1px solid #000000 !important;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05) !important;
  border-radius: 24px !important;
  width: 100%;
}

.customButton2 {
  background: white !important;
  color: #000000 !important;
  border: 1px solid #000000 !important;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05) !important;
  border-radius: 24px !important;
  width: 100%;
}

.flexContainer {
  display: flex;
  justify-content: space-between;
}

.detailContainer {
  border-bottom: 1px solid #e4e4e4;
  padding: 5%;
}

.ArtImageDetail {
  min-width: 500px;
  max-height: 250px;
  object-fit: contain;
}

@media screen and (max-width: 920px) {
  .subMissionDialog > div > div {
    min-width: auto !important;
  }

  .dialogContainer > div > div {
    min-width: 550px !important;
  }
}

@media screen and (max-width: 580px) {
  .gridBlocks {
    display: block !important;
  }

  .gridBlocks > div {
    min-width: 100%;
  }

  .dialogContainer > div > div {
    min-width: auto !important;
  }
}

@media screen and (max-width: 767px) {
  .layoutDrawer {
    position: fixed;
    z-index: 1200;
  }

  .responsiveMain {
    margin-left: 90px;
  }
}
