.contestContainer {
  display: flex;
  flex-direction: column;
  width: 50%;
  gap: 10px;
}

@media screen and (max-width: 1200px) {
  .contestContainer {
    width: 100%;
  }
}

@media (min-width: 1200px) and (max-width: 1400px) {
  .contestContainer {
    width: 75%;
  }
}
